
import { supabase } from "@/integrations/supabase/client";
import { fetchUserProfile } from "../profileUtils";
import { translateError, extractErrorMessage } from "../errorTranslator";
import { clearAuthData, safeSetItem } from "../sessionUtils";
import { AuthUser, LoginResult } from "./types";
import { clearUserCache } from "./userCache";

export const loginUser = async (email: string, password: string, stayLoggedIn: boolean = false): Promise<LoginResult> => {
  console.log("loginUser: Tentando login com:", email, "stayLoggedIn:", stayLoggedIn);
  
  if (!email || !password) {
    return { 
      user: null, 
      profile: null, 
      error: "Email e senha são obrigatórios" 
    };
  }
  
  try {
    // Clear user cache
    clearUserCache();
    
    // Verificar quaisquer sessões anteriores e limpar
    console.log("loginUser: Verificando sessões anteriores");
    const previousSession = await supabase.auth.getSession();
    if (previousSession.data.session) {
      console.log("loginUser: Sessão anterior encontrada, realizando logout");
      await supabase.auth.signOut({ scope: 'global' });
      
      // Breve pausa para garantir que o logout foi processado
      await new Promise(resolve => setTimeout(resolve, 300));
    }
    
    // Limpar completamente quaisquer dados de autenticação anteriores
    console.log("loginUser: Limpando dados de sessão anteriores");
    clearAuthData();
    
    // Adicionar um pequeno atraso para garantir que os dados anteriores sejam limpos
    await new Promise(resolve => setTimeout(resolve, 300));
    
    console.log("loginUser: Iniciando nova tentativa de login com email:", email);
    
    // Store the stayLoggedIn preference for session management
    try {
      sessionStorage.setItem('stayLoggedIn', stayLoggedIn ? 'true' : 'false');
    } catch (e) {
      console.warn("Não foi possível armazenar preferência de sessão:", e);
    }
    
    // Tentar login com as credenciais fornecidas
    // Note: We can't directly set the session duration in signInWithPassword options
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email.trim().toLowerCase(),
      password: password
    });
    
    if (error) {
      console.error("loginUser: Erro de autenticação:", error);
      return {
        user: null,
        profile: null,
        error: translateError(error.message) || "Credenciais inválidas ou erro no servidor"
      };
    }
    
    if (!data.user) {
      console.error("loginUser: Login bem-sucedido mas sem dados do usuário");
      return {
        user: null,
        profile: null,
        error: "Falha no login. Usuário não encontrado."
      };
    }
    
    console.log("loginUser: Usuário autenticado com sucesso:", data.user.id);
    
    // Fetch profile data after successful login
    console.log("loginUser: Buscando dados do perfil");
    const profileData = await fetchUserProfile(data.user.id);
    console.log("loginUser: Dados do perfil obtidos:", profileData ? "sim" : "não");
    
    // Store authentication status
    try {
      sessionStorage.setItem('isAuthenticated', 'true');
    } catch (e) {
      console.warn("Não foi possível armazenar status de autenticação:", e);
    }
    
    // Store the role for faster subsequent loads
    if (profileData?.role) {
      safeSetItem(localStorage, 'userRole', profileData.role);
    }
    
    // Verify session to confirm login was successful
    const sessionCheck = await supabase.auth.getSession();
    console.log("Verificação de sessão após login:", 
      sessionCheck.data.session ? "Sessão existe" : "Nenhuma sessão encontrada");
    
    // Reset user cache
    clearUserCache();
    
    // Return user and profile data
    return { 
      user: {
        ...data.user,
        role: profileData?.role || null
      }, 
      profile: profileData,
      error: undefined
    };
  } catch (error: any) {
    console.error("loginUser: Erro na tentativa de login:", error);
    return {
      user: null,
      profile: null,
      error: extractErrorMessage(error) || "Erro inesperado durante o login"
    };
  }
};
