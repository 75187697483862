
import { QueryClient } from '@tanstack/react-query';

// Create a QueryClient instance with optimized default settings
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes before data is considered stale
      retry: 1, // Only retry failed requests once
      refetchOnWindowFocus: false, // Don't refetch when window regains focus
      gcTime: 10 * 60 * 1000, // Cache data for 10 minutes (replaces cacheTime)
    },
  },
});

// Function to invalidate proposal-related queries
export const invalidateProposalQueries = () => {
  return queryClient.invalidateQueries({ queryKey: ['proposals'] });
};

// Function to set cache headers for fetch requests
export const getFetchOptions = () => {
  return {
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    }
  };
};
