
import { supabase } from '@/integrations/supabase/client';

/**
 * Realiza busca de autocomplete para medicamentos
 */
export const searchMedicationsAutocomplete = async (query: string) => {
  if (query.length < 2) return [];
  
  try {
    console.log('Executando autocomplete para:', query);
    
    // Tenta usar a função RPC para autocomplete
    try {
      const { data, error } = await supabase.rpc('search_medications_autocomplete', { query });
      
      if (error) {
        console.warn('RPC search_medications_autocomplete falhou:', error);
        throw error;
      }
      
      console.log('Resultados do autocomplete (RPC):', data?.length || 0);
      return data || [];
      
    } catch (rpcError) {
      console.warn('Erro ao chamar RPC search_medications_autocomplete:', rpcError);
      
      // Fallback para consulta direta - inclui busca por princípios ativos e indications
      const { data, error } = await supabase
        .from('medications')
        .select('id, name, manufacturer, price, slug, indications, active_ingredient')
        .or(`name.ilike.%${query}%,active_ingredient.ilike.%${query}%,indications.ilike.%${query}%`)
        .order('name')
        .limit(10);
      
      if (error) {
        console.error('Erro na consulta direta de autocomplete:', error);
        throw error;
      }
      
      console.log('Resultados do autocomplete (query direta):', data?.length || 0);
      return data || [];
    }
    
  } catch (err) {
    console.error('Erro final na busca de autocomplete:', err);
    return [];
  }
};

/**
 * Realiza busca completa de medicamentos
 */
export const searchMedicationsFull = async (query: string) => {
  if (!query.trim()) return [];
  
  try {
    console.log('Executando busca completa para:', query);
    const cleanQuery = query.trim();
    
    // Tenta usar a função RPC para busca completa
    try {
      const { data, error } = await supabase.rpc('search_medications_full', { query: cleanQuery });
      
      if (error) {
        console.warn('RPC search_medications_full falhou:', error);
        throw error;
      }
      
      console.log('Resultados da busca completa (RPC):', data?.length || 0);
      
      // Ordenar resultados para dar prioridade aos que começam com o termo de busca
      const sortedData = (data || []).sort((a, b) => {
        const aStartsWithTerm = a.name.toLowerCase().startsWith(cleanQuery.toLowerCase());
        const bStartsWithTerm = b.name.toLowerCase().startsWith(cleanQuery.toLowerCase());
        
        if (aStartsWithTerm && !bStartsWithTerm) return -1;
        if (!aStartsWithTerm && bStartsWithTerm) return 1;
        return a.name.localeCompare(b.name);
      });
      
      return sortedData;
      
    } catch (rpcError) {
      console.warn('Erro ao chamar RPC search_medications_full:', rpcError);
      
      // Fallback para consulta direta - inclui busca por indicações, sintomas e princípios ativos
      const { data, error } = await supabase
        .from('medications')
        .select('id, name, manufacturer, price, slug, indications, active_ingredient')
        .or(`name.ilike.%${cleanQuery}%,manufacturer.ilike.%${cleanQuery}%,indications.ilike.%${cleanQuery}%,active_ingredient.ilike.%${cleanQuery}%`)
        .order('name')
        .limit(50);
      
      if (error) {
        console.error('Erro na consulta direta de busca completa:', error);
        throw error;
      }
      
      console.log('Resultados da busca direta:', data?.length || 0);
      
      // Ordenar resultados para dar prioridade aos que começam com o termo de busca
      const sortedData = (data || []).sort((a, b) => {
        const aStartsWithTerm = a.name.toLowerCase().startsWith(cleanQuery.toLowerCase());
        const bStartsWithTerm = b.name.toLowerCase().startsWith(cleanQuery.toLowerCase());
        
        if (aStartsWithTerm && !bStartsWithTerm) return -1;
        if (!aStartsWithTerm && bStartsWithTerm) return 1;
        return a.name.localeCompare(b.name);
      });
      
      return sortedData;
    }
    
  } catch (err) {
    console.error('Erro final na busca completa:', err);
    return [];
  }
};

/**
 * Registra uma busca no sistema para analytics
 */
export const logSearch = async (searchTerm: string, foundResults: boolean, resultCount: number) => {
  try {
    console.log(`Registrando busca: "${searchTerm}" - Resultados: ${resultCount}`);
    
    // Using try-catch instead of .catch()
    try {
      const { error } = await supabase.rpc('log_search', {
        p_search_term: searchTerm,
        p_found_results: foundResults,
        p_result_count: resultCount
      });
      
      if (error) {
        console.warn('Função log_search indisponível:', error);
      } else {
        console.log('Busca registrada com sucesso');
      }
    } catch (e) {
      console.warn('Erro ao chamar RPC log_search:', e);
    }
  } catch (err) {
    console.error('Erro ao registrar busca:', err);
  }
};
