
import { supabase } from "@/integrations/supabase/client";
import { clearAuthData, recoverSession } from "../sessionUtils";
import { AuthUser } from "./types";
import { fetchUserProfile } from "../profileUtils";
import { clearUserCache, setUserCache } from "./userCache";
import { safeSetItem } from "../sessionUtils";

export const getCurrentUser = async () => {
  try {
    // Check cache first to prevent excessive calls
    const { getUserCache, isUserCacheValid } = await import("./userCache");
    const userCache = getUserCache();
    
    if (isUserCacheValid()) {
      return userCache.user;
    }
    
    console.log("getCurrentUser: Verificando sessão atual");
    const { data, error } = await supabase.auth.getSession();
    
    if (error) {
      console.error("getCurrentUser: Erro ao verificar sessão:", error.message);
      clearAuthData(); // Clean up potentially corrupted session data
      setUserCache(null);
      return null;
    }
    
    if (!data?.session?.user) {
      console.log("getCurrentUser: Nenhuma sessão encontrada");
      
      // Try to recover the session
      const recovered = await recoverSession();
      if (recovered) {
        // If session recovered, fetch it again
        const { data: refreshedData } = await supabase.auth.getSession();
        if (refreshedData?.session?.user) {
          console.log("getCurrentUser: Sessão recuperada com sucesso");
        } else {
          // Clean up if recovery seemed to work but no session exists
          clearAuthData();
          setUserCache(null);
          return null;
        }
      } else {
        // No recovery possible, clean up
        clearAuthData();
        setUserCache(null);
        return null;
      }
    }
    
    const userId = data.session.user.id;
    const userEmail = data.session.user.email;
    console.log("getCurrentUser: Sessão encontrada para usuário:", userId);

    // Get user profile data
    const profileData = await fetchUserProfile(userId);
    
    if (!profileData) {
      console.warn("getCurrentUser: Nenhum perfil encontrado para o usuário:", userId);
    }
    
    // Store authentication status for faster subsequent checks
    try {
      sessionStorage.setItem('isAuthenticated', 'true');
    } catch (e) {
      console.warn("Não foi possível armazenar status de autenticação:", e);
    }
    
    // Store the role in localStorage for faster subsequent loads
    if (profileData?.role) {
      safeSetItem(localStorage, 'userRole', profileData.role);
    }
    
    // Cache user data to prevent excess calls
    const userData = {
      ...data.session.user,
      role: profileData?.role || null,
      name: profileData?.name || null
    };
    
    setUserCache(userData);
    
    return userData;
  } catch (error) {
    console.error("Erro em getCurrentUser:", error);
    clearAuthData(); // Clean up on error
    setUserCache(null);
    return null;
  }
};
