
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { DatePicker } from "@/components/ui/date-picker";
import { PhoneInput } from "@/components/ui/phone-input";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface JudicialFormFieldProps {
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  required?: boolean;
  pattern?: string;
  inputMode?: "text" | "tel" | "email" | "url" | "search" | "none" | "numeric" | "decimal";
  autoComplete?: string;
  placeholder?: string;
  isTextarea?: boolean;
  rows?: number;
  isDatePicker?: boolean;
  isPhoneInput?: boolean;
  countryCode?: string;
  onCountryCodeChange?: (code: string) => void;
}

export const JudicialFormField = ({
  label,
  id,
  name,
  value,
  onChange,
  type = "text",
  required = false,
  pattern,
  inputMode,
  autoComplete,
  placeholder,
  isTextarea = false,
  rows = 4,
  isDatePicker = false,
  isPhoneInput = false,
  countryCode = "55",
  onCountryCodeChange
}: JudicialFormFieldProps) => {
  
  // Handler para o DatePicker
  const handleDateChange = (date: Date | undefined) => {
    if (date && onChange) {
      const fakeEvent = {
        target: {
          name,
          value: format(date, "yyyy-MM-dd")
        }
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(fakeEvent);
    }
  };
  
  // Handler para o PhoneInput
  const handlePhoneChange = (phoneValue: string) => {
    if (onChange) {
      const fakeEvent = {
        target: {
          name,
          value: phoneValue
        }
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(fakeEvent);
    }
  };
  
  return (
    <div className="group">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 mb-1 font-roboto"
      >
        {label} {required && <span className="text-primary">*</span>}
      </label>
      
      {isTextarea ? (
        <Textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          rows={rows}
          className="w-full border border-gray-300 rounded-lg px-3 py-2 text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-primary/50 font-roboto transition-all group-hover:border-primary/50"
          placeholder={placeholder}
          required={required}
          aria-required={required}
        />
      ) : isDatePicker ? (
        <div className="relative">
          <DatePicker
            mode="single"
            selected={value ? new Date(value) : undefined}
            onSelect={handleDateChange}
            disabled={false}
            initialFocus={false}
            allowPaste={true}
            placeholder={placeholder || "DD/MM/AAAA"}
          />
        </div>
      ) : isPhoneInput ? (
        <PhoneInput
          id={id}
          value={value}
          onChange={handlePhoneChange}
          countryCode={countryCode || "55"}
          onCountryCodeChange={(code) => {
            if (onCountryCodeChange) onCountryCodeChange(code);
          }}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          inputMode={inputMode}
        />
      ) : (
        <Input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full border border-gray-300 rounded-lg px-3 py-2 text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-primary/50 font-roboto transition-all group-hover:border-primary/50"
          required={required}
          pattern={pattern}
          inputMode={inputMode}
          autoComplete={autoComplete}
          placeholder={placeholder}
          aria-required={required ? "true" : undefined}
        />
      )}
    </div>
  );
};
