
// This file re-exports both auth and profile utils for backward compatibility

import { initActivityListeners } from "./sessionUtils";
import { getCurrentUser, loginUser, logoutUser, resetPassword, LoginResult } from "./authUtils";
import { UserProfile, isAdminRole, isAdminUser, SimpleUserProfile } from "./profileUtils";
import { checkUserExists } from "./userUtils";

// Initialize activity listeners when this file is imported
initActivityListeners();

// Re-export all the functions from the various files
export {
  getCurrentUser,
  loginUser,
  logoutUser,
  isAdminRole,
  isAdminUser,
  checkUserExists,
  resetPassword
};

// Re-export the types as well
export type {
  LoginResult,
  UserProfile,
  SimpleUserProfile
};
