
import * as React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";

export interface PhoneInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: string;
  onChange: (value: string) => void;
  countryCode: string;
  onCountryCodeChange: (code: string) => void;
  className?: string;
  inputClassName?: string;
}

type CountryCode = {
  code: string;
  name: string;
  dial_code: string;
};

// Lista simplificada de países com seus códigos telefônicos
const countryCodes: CountryCode[] = [
  { code: "BR", name: "Brasil", dial_code: "55" },
  { code: "US", name: "Estados Unidos", dial_code: "1" },
  { code: "PT", name: "Portugal", dial_code: "351" },
  { code: "ES", name: "Espanha", dial_code: "34" },
  { code: "FR", name: "França", dial_code: "33" },
  { code: "IT", name: "Itália", dial_code: "39" },
  { code: "UK", name: "Reino Unido", dial_code: "44" },
  { code: "DE", name: "Alemanha", dial_code: "49" },
  { code: "JP", name: "Japão", dial_code: "81" },
  { code: "CN", name: "China", dial_code: "86" },
  { code: "AU", name: "Austrália", dial_code: "61" },
  { code: "CA", name: "Canadá", dial_code: "1" },
  { code: "AR", name: "Argentina", dial_code: "54" },
  { code: "CL", name: "Chile", dial_code: "56" },
  { code: "CO", name: "Colômbia", dial_code: "57" },
  { code: "MX", name: "México", dial_code: "52" },
  { code: "UY", name: "Uruguai", dial_code: "598" },
  { code: "PY", name: "Paraguai", dial_code: "595" },
  { code: "BO", name: "Bolívia", dial_code: "591" },
  { code: "PE", name: "Peru", dial_code: "51" },
  // Adicionando mais países para cobrir a maioria das regiões
  { code: "ZA", name: "África do Sul", dial_code: "27" },
  { code: "AE", name: "Emirados Árabes", dial_code: "971" },
  { code: "SA", name: "Arábia Saudita", dial_code: "966" },
  { code: "RU", name: "Rússia", dial_code: "7" },
  { code: "IN", name: "Índia", dial_code: "91" },
  { code: "PK", name: "Paquistão", dial_code: "92" },
  { code: "TH", name: "Tailândia", dial_code: "66" },
  { code: "VN", name: "Vietnã", dial_code: "84" },
  { code: "KR", name: "Coreia do Sul", dial_code: "82" },
  { code: "ID", name: "Indonésia", dial_code: "62" },
  { code: "MY", name: "Malásia", dial_code: "60" },
  { code: "PH", name: "Filipinas", dial_code: "63" },
  { code: "NZ", name: "Nova Zelândia", dial_code: "64" },
  { code: "SG", name: "Singapura", dial_code: "65" },
];

export function PhoneInput({
  value,
  onChange,
  countryCode,
  onCountryCodeChange,
  className,
  inputClassName,
  ...props
}: PhoneInputProps) {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  
  // Encontra o país atual com base no código
  const selectedCountry = React.useMemo(() => {
    return countryCodes.find(country => country.dial_code === countryCode) || countryCodes[0];
  }, [countryCode]);
  
  // Filtra países pelo termo de busca
  const filteredCountries = React.useMemo(() => {
    if (!searchTerm) return countryCodes;
    
    return countryCodes.filter(country => 
      country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      country.dial_code.includes(searchTerm)
    );
  }, [searchTerm]);
  
  return (
    <div className={cn("flex", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="flex-shrink-0 w-[85px] px-2 gap-1 text-xs font-normal justify-between rounded-r-none border-r-0"
            type="button"
          >
            +{selectedCountry.dial_code}
            <ChevronsUpDown className="ml-1 h-3 w-3 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[220px] p-0" align="start">
          <Command>
            <CommandInput 
              placeholder="Buscar país..." 
              onValueChange={setSearchTerm}
              value={searchTerm}
            />
            <CommandEmpty>Nenhum país encontrado.</CommandEmpty>
            <CommandList>
              <ScrollArea className="h-[200px]">
                <CommandGroup>
                  {filteredCountries.map((country) => (
                    <CommandItem
                      key={country.code}
                      value={country.name}
                      onSelect={() => {
                        onCountryCodeChange(country.dial_code);
                        setOpen(false);
                        setSearchTerm("");
                      }}
                      className="flex items-center justify-between"
                    >
                      <span className="truncate">
                        {country.name} +{country.dial_code}
                      </span>
                      {country.dial_code === countryCode && (
                        <Check className="ml-auto h-4 w-4 flex-shrink-0" />
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Input
        type="tel"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={cn("rounded-l-none", inputClassName)}
        inputMode="tel"
        {...props}
      />
    </div>
  );
}
