
import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";

export type DatePickerProps = {
  mode: "single" | "range";
  selected?: Date | Date[] | undefined;
  onSelect?: (date: Date | undefined) => void;
  disabled?: boolean;
  initialFocus?: boolean;
  allowPaste?: boolean; // Nova propriedade para permitir colar
  placeholder?: string;
};

export function DatePicker({
  mode = "single",
  selected,
  onSelect,
  disabled = false,
  initialFocus = false,
  allowPaste = false,
  placeholder = "Selecione uma data",
}: DatePickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>("");
  
  React.useEffect(() => {
    if (selected instanceof Date) {
      setInputValue(format(selected, "dd/MM/yyyy", { locale: ptBR }));
    } else {
      setInputValue("");
    }
  }, [selected]);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    
    // Tenta converter a string para uma data válida
    if (e.target.value) {
      try {
        // Aceita formatos comuns: dd/MM/yyyy, dd-MM-yyyy, yyyy-MM-dd
        let dateValue: Date | undefined;
        
        // Verifica formato dd/MM/yyyy ou dd-MM-yyyy
        if (/^\d{2}[/-]\d{2}[/-]\d{4}$/.test(e.target.value)) {
          const [day, month, year] = e.target.value.split(/[/-]/).map(Number);
          dateValue = new Date(year, month - 1, day);
        }
        // Verifica formato yyyy-MM-dd
        else if (/^\d{4}[/-]\d{2}[/-]\d{2}$/.test(e.target.value)) {
          const [year, month, day] = e.target.value.split(/[/-]/).map(Number);
          dateValue = new Date(year, month - 1, day);
        }
        
        if (dateValue && !isNaN(dateValue.getTime()) && onSelect) {
          onSelect(dateValue);
          setIsCalendarOpen(false);
        }
      } catch (error) {
        console.error("Erro ao converter data:", error);
      }
    }
  };
  
  const handleInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (!allowPaste) return;
    
    const pastedText = e.clipboardData.getData('text');
    if (!pastedText) return;
    
    // Tenta converter o texto colado para uma data válida
    try {
      // Aceita formatos comuns: dd/MM/yyyy, dd-MM-yyyy, yyyy-MM-dd
      let dateValue: Date | undefined;
      
      // Verifica formato dd/MM/yyyy ou dd-MM-yyyy
      if (/^\d{2}[/-]\d{2}[/-]\d{4}$/.test(pastedText)) {
        const [day, month, year] = pastedText.split(/[/-]/).map(Number);
        dateValue = new Date(year, month - 1, day);
      }
      // Verifica formato yyyy-MM-dd
      else if (/^\d{4}[/-]\d{2}[/-]\d{2}$/.test(pastedText)) {
        const [year, month, day] = pastedText.split(/[/-]/).map(Number);
        dateValue = new Date(year, month - 1, day);
      }
      
      if (dateValue && !isNaN(dateValue.getTime()) && onSelect) {
        e.preventDefault(); // Previne o comportamento padrão
        onSelect(dateValue);
        setInputValue(format(dateValue, "dd/MM/yyyy", { locale: ptBR }));
        setIsCalendarOpen(false);
      }
    } catch (error) {
      console.error("Erro ao converter data colada:", error);
    }
  };
  
  // Renderiza o componente Calendar tradicional quando allowPaste é false
  if (!allowPaste) {
    return (
      <Calendar
        mode={mode as any}
        selected={selected}
        onSelect={onSelect as any}
        disabled={disabled}
        initialFocus={initialFocus}
        locale={ptBR}
        className="pointer-events-auto"
      />
    );
  }
  
  // Renderiza a versão com input para colar quando allowPaste é true
  return (
    <div className="flex flex-col space-y-2">
      <div className="relative">
        <Input
          className="pl-10"
          value={inputValue}
          onChange={handleInputChange}
          onPaste={handleInputPaste}
          placeholder={placeholder}
          disabled={disabled}
        />
        <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
        <div className="absolute right-1 top-1">
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="h-7 w-7 p-0"
                onClick={() => setIsCalendarOpen(true)}
              >
                <span className="sr-only">Abrir calendário</span>
                <CalendarIcon className="h-4 w-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-auto p-0">
              <Calendar
                mode={mode as any}
                selected={selected}
                onSelect={(value) => {
                  if (onSelect) {
                    onSelect(value);
                    setIsCalendarOpen(false);
                  }
                }}
                disabled={disabled}
                initialFocus={initialFocus}
                locale={ptBR}
                className="pointer-events-auto"
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
}
