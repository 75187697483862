
import { supabase } from "@/integrations/supabase/client";
import { clearAuthData } from "../sessionUtils";
import { clearUserCache } from "./userCache";

export const logoutUser = async (redirect = true) => {
  try {
    // Reset user cache
    clearUserCache();
    
    console.log("logoutUser: Iniciando processo de logout");
    
    // Limpar dados de autenticação do armazenamento local
    clearAuthData();
    
    const { error } = await supabase.auth.signOut({
      scope: 'global' // Ensure full logout, not just local
    });
    
    if (error) {
      console.error("logoutUser: Erro ao fazer logout:", error.message);
      throw error;
    }
    
    console.log("logoutUser: Logout realizado com sucesso");
    
    // Aguardar um pouco para garantir que a sessão foi completamente limpa
    await new Promise(resolve => setTimeout(resolve, 300));
    
    // Forçar redirecionamento para a página inicial após logout somente se redirect=true
    if (redirect) {
      window.location.href = "/login";
    }
    
    return true;
  } catch (error) {
    console.error("logoutUser: Erro no processo de logout:", error);
    throw error;
  }
};
