
import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { loginUser, getCurrentUser, resetPassword } from "@/utils/authUtils";
import { useNavigate } from "react-router-dom";
import { isAdminRole } from "@/utils/profileUtils";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loading } from "@/components/ui/loading";
import { debugSession, testDirectAuth } from "@/utils/userUtils";
import { supabase } from "@/integrations/supabase/client";
import { clearAuthData } from "@/utils/sessionUtils";
import { translateError } from "@/utils/errorTranslator";
import { optimizeImage } from "@/utils/imageOptimizer";
import { Eye, EyeOff, Mail, Lock } from "lucide-react";
import { GlassCard } from "@/components/GlassCard";
import { Checkbox } from "@/components/ui/checkbox";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [resetMessage, setResetMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  // Logo path using local resource
  const logoPath = "/lovable-uploads/00db7f97-33a2-4007-a490-06f3145c2c2a.png";
  const optimizedLogoUrl = optimizeImage(logoPath, {
    size: 'medium',
    format: 'webp',
    quality: 100
  });

  useEffect(() => {
    // Clear any old authentication data to ensure a clean state
    clearAuthData();
    
    // Ensure any previous session is terminated
    supabase.auth.signOut({ scope: 'global' })
      .catch(err => {
        console.error("Erro ao encerrar sessão anterior:", err);
      })
      .finally(() => {
        // After any sign out attempt, check auth status
        checkAuth();
      });
    
    // Load different function to avoid duplicate code
    function checkAuth() {
      try {
        setIsCheckingAuth(true);
        
        // Check if there's a valid session
        getCurrentUser().then(user => {
          if (user) {
            console.log("Usuário já logado:", user);
            
            // If user is already logged in and has admin access, redirect to dashboard
            if (isAdminRole(user.role)) {
              console.log("Usuário tem acesso de administrador, redirecionando para dashboard");
              toast({
                title: "Login automático",
                description: "Você já está autenticado",
                variant: "success",
              });
              navigate("/admin/dashboard", { replace: true });
            } else {
              // If not admin, redirect to home page
              console.log("Usuário não tem acesso de administrador, redirecionando para home");
              toast({
                title: "Login automático",
                description: "Você já está autenticado",
                variant: "success",
              });
              navigate("/", { replace: true });
            }
          } else {
            setIsCheckingAuth(false);
          }
        }).catch(error => {
          console.error("Erro ao verificar autenticação:", error);
          setIsCheckingAuth(false);
        });
      } catch (error) {
        console.error("Erro ao verificar autenticação:", error);
        setIsCheckingAuth(false);
      }
    }
  }, [navigate, toast]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoginError(null);
    
    if (!email || !password) {
      setLoginError("Por favor, preencha todos os campos");
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Make direct login call with clean credentials and stayLoggedIn preference
      const loginResult = await loginUser(email, password, stayLoggedIn);
      
      if (loginResult.error || !loginResult.user) {
        setLoginError(loginResult.error || "Erro desconhecido no login");
        return;
      }

      toast({
        title: "Login bem-sucedido",
        description: "Você será redirecionado em instantes.",
        variant: "success",
      });
      
      // Small delay to ensure session is fully established
      setTimeout(() => {
        if (isAdminRole(loginResult.user?.role)) {
          // Redirect to dashboard if user has admin access
          navigate("/admin/dashboard", { replace: true });
        } else {
          // Redirect to home page if not admin
          navigate("/", { replace: true });
        }
      }, 1000);
      
    } catch (error: any) {
      console.error("Erro na submissão do login:", error);
      setLoginError(translateError(error.message) || "Ocorreu um erro durante o login. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setResetMessage(null);
    
    if (!resetEmail) {
      setResetMessage({ type: 'error', text: "Por favor, informe o seu email" });
      return;
    }
    
    try {
      setIsResetting(true);
      
      const result = await resetPassword(resetEmail);
      
      if (!result.success) {
        setResetMessage({ 
          type: 'error', 
          text: translateError(result.error || "") || "Não foi possível enviar o email de recuperação. Tente novamente mais tarde." 
        });
        return;
      }
      
      setResetMessage({ 
        type: 'success', 
        text: "Email de recuperação enviado com sucesso. Verifique sua caixa de entrada." 
      });
      
      // Clear email and return to login screen after 3 seconds
      setTimeout(() => {
        setShowResetPassword(false);
        setResetEmail("");
      }, 3000);
    } catch (error: any) {
      console.error("Erro ao solicitar redefinição de senha:", error);
      setResetMessage({ 
        type: 'error', 
        text: translateError(error.message) || "Ocorreu um erro ao processar sua solicitação. Tente novamente." 
      });
    } finally {
      setIsResetting(false);
    }
  };

  if (isCheckingAuth) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-white to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <Loading size="lg" text="Verificando autenticação..." />
      </div>
    );
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-br from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 p-4 sm:p-6 md:p-8">
      <div className="absolute top-0 left-0 w-full h-full bg-white/20 dark:bg-black/10 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -left-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl"></div>
        <div className="absolute top-1/4 -right-20 w-60 h-60 bg-primary/20 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-40 left-1/3 w-80 h-80 bg-primary/10 rounded-full blur-3xl"></div>
      </div>
      
      <GlassCard className="w-full max-w-md p-6 sm:p-8 space-y-6 relative z-10">
        <div className="text-center">
          <a href="/" className="inline-block">
            <img
              className="mx-auto h-16 w-auto drop-shadow-md transition-all duration-300 hover:scale-105"
              src={optimizedLogoUrl}
              alt="Preço Medicamentos"
              onError={(e) => {
                // Fallback to original logo if optimization fails
                const img = e.currentTarget;
                img.src = logoPath;
              }}
            />
          </a>
          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {showResetPassword ? "Recuperação de senha" : "Acesse sua conta"}
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
            {showResetPassword 
              ? "Informe seu email para receber instruções de recuperação" 
              : "Digite seu e-mail e senha para acessar o painel administrativo"}
          </p>
        </div>
        
        <div className="space-y-6">
          {!showResetPassword ? (
            <>
              {loginError && (
                <Alert variant="destructive" className="mb-6 border-red-200 bg-red-50 text-red-800 dark:bg-red-900/20 dark:text-red-300 dark:border-red-800/30">
                  <AlertDescription>{loginError}</AlertDescription>
                </Alert>
              )}
              
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="space-y-2">
                  <Label htmlFor="email" className="text-sm font-medium text-gray-700 dark:text-gray-300">E-mail</Label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">
                      <Mail size={18} />
                    </div>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="pl-10 bg-white/80 dark:bg-gray-800/80 border-gray-200 dark:border-gray-700 focus:ring-primary focus:border-primary transition-all"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={isLoading}
                      placeholder="seu@email.com"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="password" className="text-sm font-medium text-gray-700 dark:text-gray-300">Senha</Label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">
                      <Lock size={18} />
                    </div>
                    <Input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      required
                      className="pl-10 pr-10 bg-white/80 dark:bg-gray-800/80 border-gray-200 dark:border-gray-700 focus:ring-primary focus:border-primary transition-all"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                    </button>
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="stayLoggedIn" 
                    checked={stayLoggedIn} 
                    onCheckedChange={(checked) => setStayLoggedIn(checked === true)}
                  />
                  <Label 
                    htmlFor="stayLoggedIn" 
                    className="text-sm font-medium text-gray-700 dark:text-gray-300 cursor-pointer"
                  >
                    Permanecer logado
                  </Label>
                </div>

                <div>
                  <Button 
                    type="submit" 
                    className="w-full bg-primary hover:bg-primary/90 text-white h-11 rounded-md transition-all duration-200 shadow-md hover:shadow-lg"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Loading inline size="sm" text="Entrando..." />
                    ) : "Entrar"}
                  </Button>
                </div>
              </form>
              
              <div className="text-center">
                <button
                  onClick={() => setShowResetPassword(true)}
                  className="text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                >
                  Esqueceu sua senha?
                </button>
              </div>
            </>
          ) : (
            <>
              {resetMessage && (
                <Alert 
                  variant={resetMessage.type === 'success' ? "success" : "destructive"} 
                  className={`mb-6 ${
                    resetMessage.type === 'success' 
                      ? 'border-green-200 bg-green-50 text-green-800 dark:bg-green-900/20 dark:text-green-300 dark:border-green-800/30' 
                      : 'border-red-200 bg-red-50 text-red-800 dark:bg-red-900/20 dark:text-red-300 dark:border-red-800/30'
                  }`}
                >
                  <AlertDescription>{resetMessage.text}</AlertDescription>
                </Alert>
              )}
              
              <form className="space-y-6" onSubmit={handleResetPassword}>
                <div className="space-y-2">
                  <Label htmlFor="reset-email" className="text-sm font-medium text-gray-700 dark:text-gray-300">E-mail</Label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">
                      <Mail size={18} />
                    </div>
                    <Input
                      id="reset-email"
                      name="reset-email"
                      type="email"
                      autoComplete="email"
                      required
                      className="pl-10 bg-white/80 dark:bg-gray-800/80 border-gray-200 dark:border-gray-700 focus:ring-primary focus:border-primary transition-all"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      disabled={isResetting}
                      placeholder="seu@email.com"
                    />
                  </div>
                </div>

                <div>
                  <Button 
                    type="submit" 
                    className="w-full bg-primary hover:bg-primary/90 text-white h-11 rounded-md transition-all duration-200 shadow-md hover:shadow-lg" 
                    disabled={isResetting}
                  >
                    {isResetting ? (
                      <Loading inline size="sm" text="Enviando..." />
                    ) : "Enviar email de recuperação"}
                  </Button>
                </div>
              </form>
              
              <div className="text-center">
                <button
                  onClick={() => {
                    setShowResetPassword(false);
                    setResetMessage(null);
                  }}
                  className="text-sm font-medium text-primary hover:text-primary/80 transition-colors"
                >
                  Voltar para o login
                </button>
              </div>
            </>
          )}
        </div>
      </GlassCard>
    </div>
  );
}
