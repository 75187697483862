
import { supabase } from "@/integrations/supabase/client";
import { translateError, extractErrorMessage } from "../errorTranslator";
import { ResetPasswordResult } from "./types";

export const resetPassword = async (email: string): Promise<ResetPasswordResult> => {
  if (!email) {
    return {
      success: false,
      error: "Email é obrigatório"
    };
  }
  
  try {
    console.log("resetPassword: Enviando email de recuperação para:");
    
    // Configurar a URL de redirecionamento para a página de redefinição de senha
    // Usando URL absoluta para evitar problemas com caminhos relativos
    const origin = window.location.origin;
    const redirectUrl = `${origin}/reset-password`;
    
    console.log("resetPassword: URL de redirecionamento:", redirectUrl);
    
    const { error } = await supabase.auth.resetPasswordForEmail(email.trim().toLowerCase(), {
      redirectTo: redirectUrl
    });
    
    if (error) {
      console.error("resetPassword: Erro ao enviar email:", error.message);
      return {
        success: false,
        error: translateError(error.message) || "Erro ao enviar email de recuperação"
      };
    }
    
    console.log("resetPassword: Email de recuperação enviado com sucesso");
    return {
      success: true
    };
  } catch (error: any) {
    console.error("resetPassword: Erro inesperado:", error);
    return {
      success: false,
      error: extractErrorMessage(error) || "Erro inesperado ao processar a recuperação de senha"
    };
  }
};
