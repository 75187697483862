
import { useState, useEffect } from "react";
import { SearchBox } from "@/components/SearchBox";

export const HeroSearch = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [animationsEnabled, setAnimationsEnabled] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationsEnabled(true);
      setIsSearchVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`transition-opacity duration-500 ${isSearchVisible ? 'opacity-100' : 'opacity-0'}`}>
      <SearchBox 
        className="w-full max-w-4xl mx-auto transition-transform duration-300 mt-8 shadow-lg hover:shadow-xl" 
        showSuggestions={true} 
      />
      <div className="text-center mt-2 text-sm text-gray-500">
        Busque por nome de medicamento ou princípio ativo
      </div>
    </div>
  );
};
