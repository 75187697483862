import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Formats a phone number for display with country code
 */
export function formatPhoneDisplay(phone: string, countryCode: string = "55"): string {
  if (!phone) return "";
  
  // Remove non-numeric characters
  const cleanPhone = phone.replace(/\D/g, "");
  
  // Format with country code
  return `+${countryCode} ${cleanPhone}`;
}
