
import { AuthUser } from "./types";

// Cache to prevent duplicate getCurrentUser calls in the same render cycle
let userCache: {
  user: AuthUser | null;
  timestamp: number;
} | null = null;

export const CACHE_TTL = 3000; // 3 seconds

export const getUserCache = () => userCache;

export const setUserCache = (user: AuthUser | null) => {
  userCache = { 
    user, 
    timestamp: Date.now() 
  };
};

export const clearUserCache = () => {
  userCache = null;
};

export const isUserCacheValid = () => {
  return userCache && (Date.now() - userCache.timestamp < CACHE_TTL);
};
